<template>
  <div class="c-TranslationSidePanel">
    <transition name="fade-in" appear>
      <div class="c-TranslationSidePanel__backdrop" @click="closeSidePanel" />
    </transition>

    <transition name="slide" appear>
      <div class="c-TranslationSidePanel__panel">
        <div class="c-TranslationSidePanel__panelOverflow">
          <div class="c-TranslationSidePanel__actions">
            <div v-show="translation">
              <Breadcrumbs :items="breadcrumbs" @click="closeSidePanel" />
            </div>
            <div>
              <button
                type="button"
                :disabled="!isStepButtonDisabled(-1)"
                @click="selectTranslation(-1)"
              >
                <Icon icon="chevron-left" />
              </button>
              <button
                type="button"
                :disabled="!isStepButtonDisabled(1)"
                @click="selectTranslation(1)"
              >
                <Icon icon="chevron-right" />
              </button>

              <button type="button" @click="closeSidePanel">
                <Icon icon="close" />
              </button>
            </div>
          </div>

          <Loader
            v-if="!translation"
            class="c-TranslationSidePanel__loader"
            :active="true"
          />

          <TranslationDetails
            v-else
            :key="selectedTranslation"
            :translation="translation"
            class="mb-10"
            @closeSidePanel="closeSidePanel"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { Icon, Breadcrumbs, Loader } from '@unbabel/ui'

import TranslationDetails from './TranslationDetails/TranslationDetails.vue'
import { truncateString, getTagValue } from '@/utils/generalUtils'
import { GLOBAL_GETTERS } from '@/data/enum'

export default {
  name: 'TranslationSidePanel',
  components: {
    Icon,
    Breadcrumbs,
    TranslationDetails,
    Loader
  },
  computed: {
    ...mapState('watchtower', ['selectedTranslation', 'translations']),
    ...mapGetters({
      currentTranslationIndex: 'watchtower/currentTranslationIndex',
      translation: 'watchtower/translation',
      isLoading: GLOBAL_GETTERS.LOADING
    }),
    breadcrumbs () {
      const out = [{ label: 'Translation' }]

      const projectId = getTagValue(this.translation?.request, 'x-unbabel-project-id', '')
      if (projectId) {
        out.push({
          label: `Project ${truncateString(projectId, 10, true)}`,
          disabled: true
        })
      } else {
        const threadId = getTagValue(this.translation?.request, 'thread_id', '')
        if (threadId) {
          out.push({
            label: `Thread ${truncateString(threadId, 10, true)}`,
            disabled: true
          })
        }

        const messageId = getTagValue(this.translation?.request, 'message_id', '')
        if (messageId) {
          out.push({
            label: `Message ${truncateString(messageId, 10, true)}`,
            disabled: true
          })
        }
      }

      const uid = this.$route.params.id
      if (uid) {
        out.push({
          label: truncateString(uid, 10),
          disabled: true
        })
      }

      return out
    }
  },
  methods: {
    ...mapActions('watchtower', [
      'fetchTranslationDetails',
      'selectTranslation'
    ]),
    ...mapMutations('watchtower', ['setStateValue']),

    isStepButtonDisabled (offset) {
      const newIndex = this.currentTranslationIndex + offset
      return newIndex >= 0 && newIndex < this.translations.length
    },
    async loadTranslation () {
      this.fetchTranslationDetails(this.selectedTranslation)
    },
    closeSidePanel () {
      this.$router.push({ name: 'Flexible', query: this.$route.query })
    }
  },
  watch: {
    selectedTranslation: {
      handler: 'loadTranslation',
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.2s $default-easing;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
  transition: all $default-time $default-easing 0s;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s $default-easing;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all $default-time $default-easing 0s;
}

.c-TranslationSidePanel {
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 101;
  }

  &__panel {
    z-index: 10002;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    max-width: 1100px;
  }

  &__panelOverflow {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $un-n50;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 calc(#{$base-padding} * 2);
  }

  &__loader {
    @extend .d-flex, .center-xs;
    position: absolute !important;
    width: 100% !important;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $base-margin 0 calc(#{$base-margin} * 1.5) 0;

    div {
      display: flex;

      button {
        outline: none;
        margin: 0 calc(#{$base-margin} * 0.5) 0 0;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $un-blue-light;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
          background-color: none !important;
        }

        &:last-child {
          margin-right: -1px;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;

        &:hover {
          background-color: $un-blue-light;
        }
      }
    }
  }
}
</style>
