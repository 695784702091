import Vue from 'vue'
import * as projectsService from '../services/projectsService'

const getDefaultState = () => ({
  projects: {},
  loading: {}
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setProject (state, { id, value }) {
      Vue.set(state.projects, id, value)
    },
    setLoading (state, { id, promise }) {
      Vue.set(state.loading, id, promise)
    }
  },
  actions: {
    async getProject ({ state, commit }, { id, customerId, force }) {
      if (!id || !customerId) return

      if (!force && state.projects[id]) {
        return state.projects[id]
      }

      if (state.loading[id]) {
        return state.loading[id]
      }

      const promise = projectsService.getProject(id, customerId)

      commit('setLoading', { id, promise })
      const data = await promise
      commit('setProject', { id, value: data })
      commit('setLoading', { id, promise: null })

      return data
    }
  }
}
