import Vue from 'vue'
import router from '@/router'
import * as temporalService from '../services/temporalService'
import { GLOBAL_MUTATIONS } from '@/data/enum'

const getDefaultState = () => ({
  translations: [],
  nextPageToken: '',
  translationDetails: {},
  filters: {},
  selectedTranslation: null
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    translationById (state) {
      return (id) => state.translations.find(t => t.id === id)
    },
    currentTranslationIndex (state) {
      return state.translations.findIndex(t => t.executionId === state.selectedTranslation)
    },
    translation (state) {
      return state.translationDetails[state.selectedTranslation] ?? null
    }
  },
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setTranslationDetails (state, { translationId, value }) {
      Vue.set(state.translationDetails, translationId, value)
    },
    setStateValue (state, { field, value }) {
      Vue.set(state, field, value)
    }
  },
  actions: {
    async selectTranslation ({ state, getters, dispatch, commit }, offset = 1) {
      const index = getters.currentTranslationIndex
      if (index < 0 || index > state.translations.length - 1) return

      const translationId = state.translations[index + offset].executionId
      dispatch('fetchTranslationDetails', translationId)
      router.push({ name: 'Flexible', query: this.$route.query, params: { id: translationId } })
    },
    async fetchTranslations ({ commit }, filters = {}) {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true, { root: true })
      const { results, nextPageToken } = await temporalService.getAllTranslations({ query: filters })
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false, { root: true })

      commit('setStateValue', { field: 'filters', value: filters })
      commit('setStateValue', { field: 'translations', value: results })
      commit('setStateValue', { field: 'nextPageToken', value: nextPageToken })
    },
    async fetchNextPage ({ state, commit }) {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true, { root: true })
      const { results, nextPageToken } = await temporalService.getAllTranslations({ query: state.filters, nextPageToken: state.nextPageToken })
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false, { root: true })

      commit('setStateValue', { field: 'translations', value: [...state.translations, ...results] })
      commit('setStateValue', { field: 'nextPageToken', value: nextPageToken })
    },
    async fetchTranslationDetails ({ state, commit }, translationId, options = {}) {
      const { force } = options

      if (!force && state.translationDetails[translationId]) {
        commit('setTranslationDetails', { translationId, value: state.translationDetails[translationId] })
        return state.translationDetails[translationId]
      }

      const data = await temporalService.getTranslation(translationId)
      commit('setTranslationDetails', { translationId, value: data })
      return data
    }
  }
}
