<template>
  <div class="c-TranslationFlowDetails">
    <Card title="Pipeline" :count="templateType">
      <div slot="header-subInfo" class="c-TranslationFlowDetails__subInfo">
        <div>Time spent: {{ timeSpent }}</div>
        <div v-if="deadline" class="mt-1">Deadline: {{ deadline }}</div>
      </div>

      <div slot="header-actions" class="c-TranslationFlowDetails__cardActions">
        <a
          :href="temporalLink"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-2"
        >
          <Button
            label="Open in Temporal"
            button-type="is-outlined"
          />
        </a>

        <Button
          class="c-TranslationFlowDetails"
          label="View Details"
          button-type="is-outlined"
          @click="toggleTraceInfoVisibility"
        />
      </div>

      <div  slot="body" class="c-TranslationFlowDetails__cardBody">
        <TranslationFlow v-if="translation.stepExecution.length" :steps="translation.stepExecution" />
        <p v-else class="text--body-2 text--light text-center w-100"> No steps found. </p>
      </div>
    </Card>

    <Modal
      title="Flow Trace"
      :is-visible="traceModalVisibility"
      @toggleVisibility="toggleTraceInfoVisibility"
    >
      <div class="c-TranslationFlowDetails__modalContentHeader">
        <button
          class="c-TranslationFlowDetails__expandAll"
          type="button"
          @click="expandAll"
        />
        <button
          class="c-TranslationFlowDetails__collapseAll"
          type="button"
          @click="collapseAll"
        />
        <button
          class="c-TranslationFlowDetails__copy"
          type="button"
          @click="copyJSON"
        />
      </div>

      <div
        ref="TranslationFlowDetailsTrace"
        class="c-TranslationFlowDetails__trace"
      />
    </Modal>
  </div>
</template>

<script>
import { Card, Button } from '@unbabel/ui'
import JSONFormatter from 'json-formatter-js'

import {
  getTagValue,
  copyToClipboard,
  showNotification,
  generateCPLinks
} from '@/utils/generalUtils'
import TranslationFlow from './TranslationFlow'
import Modal from '@/components/Modal'
import { TEMPLATE_LABEL } from '@/data/enum'

export default {
  name: 'TranslationFlowDetails',
  components: {
    Card,
    Button,
    TranslationFlow,
    Modal
  },
  data () {
    return {
      traceModalVisibility: false,
      jsonFormatter: null
    }
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  computed: {
    workflow () {
      return this.translation.workflow
    },
    templateType () {
      const template = getTagValue(this.workflow, 'template_type')
      return TEMPLATE_LABEL[template]
    },
    timeSpent () {
      return this.translation.duration
    },
    temporalLink () {
      return generateCPLinks(
        {
          translationId: this.$route.params.id
        },
        'temporal'
      )
    },
    deadline () {
      const deadline = getTagValue(this.translation.request, 'deadline')
      if (deadline) return new Date(deadline).toUTCString()
      return null
    }
  },
  methods: {
    expandAll () {
      this.jsonFormatter.openAtDepth(20)
    },
    collapseAll () {
      this.jsonFormatter.openAtDepth(1)
    },
    toggleTraceInfoVisibility () {
      this.traceModalVisibility = !this.traceModalVisibility
    },
    copyJSON () {
      copyToClipboard(this.translation)
      showNotification(this, 'is-added', 'Copied trace successfully!')
    }
  },
  mounted () {
    this.jsonFormatter = new JSONFormatter(this.translation, 1, {})
    this.$refs.TranslationFlowDetailsTrace.appendChild(this.jsonFormatter.render())
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Label__text {
  max-width: 100%;
  text-transform: none;
}

.c-TranslationFlowDetails {
  &__subInfo {
    margin-top: $size--2;
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n900;

    ::v-deep .is-alert {
      color: $un-red-dark;
    }
  }

  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__modalContentHeader {
    height: 40px;
    padding: 0 $base-padding;
    background-color: $un-n100;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding: 0;
      margin: 0;
      background-color: none;
      border: none;
      margin-right: $base-margin;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__expandAll::before {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='24' viewBox='0 0 12 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.75 12.48C11.75 11.8173 11.1904 11.28 10.5 11.28H1.5C0.809644 11.28 0.25 11.8173 0.25 12.48C0.25 13.1427 0.809644 13.68 1.5 13.68H10.5C11.1904 13.68 11.75 13.1427 11.75 12.48Z' fill='%23343941'/%3E%3Cpath d='M6.00006 15.599C5.66854 15.599 5.3506 15.7255 5.11618 15.9505C4.88176 16.1756 4.75006 16.4808 4.75006 16.799V18.959C4.75006 19.0916 4.63813 19.199 4.50006 19.199H3.00006C2.59566 19.1991 2.23113 19.433 2.07638 19.7917C1.92164 20.1504 2.00715 20.5632 2.29306 20.8378L5.29306 23.7178C5.68356 24.0925 6.31656 24.0925 6.70706 23.7178L9.70706 20.8378C9.99225 20.5631 10.0773 20.1507 9.92266 19.7925C9.76801 19.4342 9.40402 19.2005 9.00006 19.2H7.50006C7.36199 19.2 7.25006 19.0925 7.25006 18.96V16.8C7.25032 16.4816 7.11875 16.1761 6.8843 15.9508C6.64985 15.7256 6.33175 15.599 6.00006 15.599V15.599Z' fill='%23343941'/%3E%3Cpath d='M6.70709 1.24032C6.31659 0.865552 5.68358 0.865552 5.29308 1.24032L2.29308 4.12032C2.00646 4.39478 1.92049 4.80807 2.07533 5.16716C2.23016 5.52626 2.59525 5.7603 3.00008 5.76H4.50008C4.63816 5.76 4.75008 5.86745 4.75008 6V8.16C4.75008 8.82274 5.30973 9.36 6.00008 9.36C6.69044 9.36 7.25008 8.82274 7.25008 8.16V5.99904C7.25064 5.86687 7.3624 5.76 7.50008 5.76H9.00008C9.40448 5.75992 9.76902 5.52602 9.92376 5.16735C10.0785 4.80867 9.99299 4.39583 9.70709 4.12128L6.70709 1.24032Z' fill='%23343941'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }

  &__collapseAll::before {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 23.67C11.6903 23.67 12.25 23.1104 12.25 22.42V18.907C12.25 18.7689 12.3619 18.657 12.5 18.657H14.316C14.5179 18.6566 14.6997 18.5347 14.7769 18.3482C14.8541 18.1616 14.8115 17.947 14.669 17.804L11.354 14.487C11.155 14.3001 10.8449 14.3001 10.646 14.487L7.33097 17.8C7.18843 17.943 7.14587 18.1576 7.22307 18.3442C7.30027 18.5307 7.48208 18.6526 7.68397 18.653H9.49997C9.63804 18.653 9.74997 18.7649 9.74997 18.903V22.42C9.74997 23.1104 10.3096 23.67 11 23.67Z' fill='%23343941'/%3E%3Cpath d='M11 0.327999C10.3096 0.327999 9.74997 0.887643 9.74997 1.578V5.091C9.74997 5.22907 9.63804 5.341 9.49997 5.341H7.68397C7.48208 5.34144 7.30027 5.46326 7.22307 5.64981C7.14587 5.83635 7.18843 6.05102 7.33097 6.194L10.646 9.511C10.7398 9.60504 10.8671 9.65789 11 9.65789C11.1328 9.65789 11.2602 9.60504 11.354 9.511L14.669 6.194C14.8115 6.05102 14.8541 5.83635 14.7769 5.64981C14.6997 5.46326 14.5179 5.34144 14.316 5.341H12.5C12.3619 5.341 12.25 5.22907 12.25 5.091V1.578C12.25 1.24648 12.1183 0.928536 11.8839 0.694116C11.6494 0.459695 11.3315 0.327999 11 0.327999V0.327999Z' fill='%23343941'/%3E%3Cpath d='M0.75 12C0.750552 12.69 1.31003 13.249 2 13.249H20C20.6904 13.249 21.25 12.6894 21.25 11.999C21.25 11.3086 20.6904 10.749 20 10.749H2C1.66831 10.749 1.35021 10.8808 1.11576 11.1155C0.881314 11.3501 0.749735 11.6683 0.75 12Z' fill='%23343941'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }

  &__copy {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(#{$base-margin} / 2);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    position: relative;

    span {
      background-color: $un-n900;
      color: $un-white;
      border-radius: $border-radius;
      padding: calc(#{$base-padding} / 4) calc(#{$base-padding} / 2);
      text-align: left;
      font-family: $primary-font;
      font-size: 12px;
      line-height: 18px;
      white-space: nowrap;
      position: absolute;
      top: 30px;
      left: -15px;
    }
  }

  &__trace {
    background-color: $un-n50;
    padding: calc(#{$base-padding} * 1.5);
    height: calc(100% - 70px);
    overflow: auto;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }
}
</style>
