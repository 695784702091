/* eslint-disable camelcase */
import { SEARCH_FILTERS_CONFIG, GROUPED_ID_FIELDS } from '@/data/filters'
import { FILTERS_DICT } from '@/data/enum'

function getKeyByValueInArray (object, param, value) {
  return Object.keys(object).filter(key => object[`${key}`][`${param}`]).find(key => object[`${key}`][`${param}`].indexOf(value) > -1)
}

export function parseURLparams (ctx) {
  const filters = {}
  let updateUrlQueryParams = false
  const urlParams = Object.assign({}, ctx.$route.query)

  // support older url params
  Object.keys(urlParams).forEach(key => {
    const currKey = getKeyByValueInArray(SEARCH_FILTERS_CONFIG, 'oldXrayUrlParamValues', key)

    if (currKey) {
      urlParams[`${currKey}`] = urlParams[`${key}`]
      delete urlParams[`${key}`]
      updateUrlQueryParams = true
    } else if (key === 'previewConversationId') {
      urlParams.pThrId = urlParams.previewConversationId
      delete urlParams.previewConversationId
      updateUrlQueryParams = true
    } else if (key === 'previewMessageId') {
      urlParams.pMsgId = urlParams.previewMessageId
      delete urlParams.previewMessageId
      updateUrlQueryParams = true
    }
  })

  // update filter values
  Object.keys(urlParams).forEach(key => {
    if (SEARCH_FILTERS_CONFIG[`${key}`] && SEARCH_FILTERS_CONFIG[`${key}`].typeOf) {
      switch (SEARCH_FILTERS_CONFIG[`${key}`].typeOf) {
        case 'number':
          filters[`${key}`] = parseInt(urlParams[`${key}`])

          if (SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter && SEARCH_FILTERS_CONFIG[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`]) {
            filters[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`] = parseInt(urlParams[`${key}`])
          }
          break
        case 'boolean':
          filters[`${key}`] = urlParams[`${key}`] === 'true'

          if (SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter && SEARCH_FILTERS_CONFIG[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`]) {
            filters[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`] = urlParams[`${key}`] === 'true'
          }
          break
        default:
          filters[`${key}`] = urlParams[`${key}`]

          if (SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter && SEARCH_FILTERS_CONFIG[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`]) {
            filters[`${SEARCH_FILTERS_CONFIG[`${key}`].feedsFilter}`] = urlParams[`${key}`]
          }
      }
    } else if (key !== 'pThrId' && key !== 'pMsgId') {
      console.error(`Unrecognized filter key ::: ${key} when parsing URL params. Please check if filter is correctly configured in filters.js`)
    }
  })

  // clear older url params (leave only the visible ones)
  Object.keys(urlParams).forEach(key => {
    if ((!SEARCH_FILTERS_CONFIG[`${key}`] ||
      (SEARCH_FILTERS_CONFIG[`${key}`] && SEARCH_FILTERS_CONFIG[`${key}`].hiddenFromXrayUrl)) &&
      key !== 'pThrId' && key !== 'pMsgId') {
      delete urlParams[`${key}`]
      updateUrlQueryParams = true
    }
  })

  if (updateUrlQueryParams) {
    ctx.$router.replace({
      name: 'Search',
      query: urlParams
    }).then(() => {
      return filters
    }, () => {
      console.error('error updating url params on finish parsing!!!')
    })
  }

  return filters
}

export function updateURLparams (ctx, params, routeName = 'Search') {
  return new Promise((resolve, reject) => {
    let hasChanged = false

    if (!Object.keys(params).length) {
      resolve()
    } else {
      const queryParams = Object.assign({}, ctx.$route.query)

      Object.keys(params).forEach(key => {
        // from all params to update on the url, do it only for the ones that shouldn't be hidden from the url
        if (SEARCH_FILTERS_CONFIG[`${key}`] && !SEARCH_FILTERS_CONFIG[`${key}`].hiddenFromXrayUrl) {
          // update booleans
          if (typeof params[`${key}`] === 'boolean' &&
          typeof queryParams[`${key}`] === 'string' &&
          params[`${key}`] !== (queryParams[`${key}`] === 'true')) {
            hasChanged = true

          // update strings
          // eslint-disable-next-line eqeqeq
          } else if (typeof params[`${key}`] != 'boolean' && params[`${key}`] != queryParams[`${key}`]) {
            hasChanged = true
          }

          // if param to update has value !== null || !== undefined, add for url update, otherwise remove it from the url
          if (params[`${key}`] !== null && params[`${key}`] !== undefined) {
            queryParams[`${key}`] = params[`${key}`]
          } else {
            delete queryParams[`${key}`]
          }
        }
      })

      if (!hasChanged) {
        return resolve()
      }

      ctx.$router.replace({
        name: routeName,
        query: queryParams
      }, () => {
        resolve()
      }, () => {
        reject(new Error('could not update filters/search as a route params'))
      })
    }
  })
}

export function generateRequestUrlParams (filterParams, searchParams) {
  const requestParams = ['?']

  Object.keys(filterParams).forEach(key => {
    if (SEARCH_FILTERS_CONFIG[`${key}`] && SEARCH_FILTERS_CONFIG[`${key}`].toBeSentOnXHRRequest) {
      // override default limit to 15 if we have a THREAD_ID or a THREAD_EXTERNAL_ID
      if ((filterParams[`${FILTERS_DICT.THREAD_ID}`] || filterParams[`${FILTERS_DICT.THREAD_EXTERNAL_ID}`]) && key === FILTERS_DICT.LIMIT) {
        requestParams.push(`${SEARCH_FILTERS_CONFIG[`${key}`].xhrQueryParam}=15`)
      // add date params if no value from the grouped input is present OR if it's a "load more"
      } else if (key === FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN || key === FILTERS_DICT.DATE_LESS_THAN) {
        if (GROUPED_ID_FIELDS.every(groupedKey => !filterParams[`${groupedKey}`]) || searchParams?.isLoadMore) {
          requestParams.push(`${SEARCH_FILTERS_CONFIG[`${key}`].xhrQueryParam}=${filterParams[`${key}`]}`)
        }
      // treat bool params
      } else if (SEARCH_FILTERS_CONFIG[`${key}`].typeOf === 'boolean') {
        const val = filterParams[`${key}`] ? 'True' : 'False'
        requestParams.push(`${SEARCH_FILTERS_CONFIG[`${key}`].xhrQueryParam}=${val}`)
      // treat params with special parsing
      } else if (SEARCH_FILTERS_CONFIG[`${key}`].xhrParamParsing && filterParams[`${key}`]) {
        const filterKey = SEARCH_FILTERS_CONFIG[`${key}`].xhrAlias ? SEARCH_FILTERS_CONFIG[`${SEARCH_FILTERS_CONFIG[`${key}`].xhrAlias}`].xhrQueryParam : SEARCH_FILTERS_CONFIG[`${key}`].xhrQueryParam
        requestParams.concat(SEARCH_FILTERS_CONFIG[`${key}`].xhrParamParsing(filterKey, filterParams[`${key}`], requestParams))
      // all of the params not on the above conditions
      } else if (filterParams[`${key}`]) {
        requestParams.push(`${SEARCH_FILTERS_CONFIG[`${key}`].xhrQueryParam}=${encodeURIComponent(filterParams[`${key}`])}`)
      }
    } else if (!SEARCH_FILTERS_CONFIG[`${key}`]) {
      console.error(`Unrecognized filter key ::: ${key} when generating XHR request params. Please check if filter is correctly configured in filters.js`)
    }
  })

  const joinParams = requestParams.join('&')
  return `${joinParams.slice(0, 1)}${joinParams.slice(2)}`
}
