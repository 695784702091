import axios from 'axios'

const BASE_URL = `${process.env.VUE_APP_UNBABEL_API_URL}/projects/v0/customers`

export async function getProject (id, customerId) {
  try {
    const { data } = await axios.get(`${BASE_URL}/${customerId}/projects/${id}`)
    return data
  } catch (e) {
    console.error(e)
  }
}
