/* eslint-disable camelcase */
import { __parseNuggetsForAnnotations } from '@/utils/parsingUtils'
import { languageOptions } from '@/data/languages'
import { parseSegments, parseTasksIntoSegments } from '../utils/parsingUtils'

export function getResultData (results) {
  const foundResult = results?.find(result => !!result?.translated_data || !!result?.output?.translated_data)
  return foundResult?.output ?? foundResult
}

export function getLanguage (code) {
  return languageOptions[code]?.label || code
}

export function getSegments (stepExecution, config) {
  const MTSteps = []
  const HTSteps = []
  const out = []
  let segments = []

  stepExecution.forEach((step, index) => {
    if (step.module === 'human_translation-run' && index === stepExecution.length - 1) HTSteps.push(step)
    if (step.module === 'human_translation-resume') HTSteps.push(step)
    else if (step.module.startsWith('machine_translation')) MTSteps.push(step)
  })

  MTSteps.forEach((step, index) => {
    const { config } = step.input ?? {}
    const { output } = step.results[0] ?? {}
    const { document } = output ?? {}

    if (!document) return out

    if (out.length === 0) {
      segments = parseSegments(document.source_segments, config)
      out.push({
        name: 'Source',
        number_of_segments: document.source_segments.length,
        language: getLanguage(document.source_language ?? config.source_language),
        segments
      })
    }

    segments = parseSegments(document.target_segments, config)
    let name = 'Machine Translation'
    if (MTSteps.length > 1) name += ` ${index + 1}`

    out.push({
      name,
      number_of_segments: document.target_segments.length,
      language: getLanguage(document.target_language ?? config.target_language),
      segments,
      request_engine: step.input?.config?.model_service?.name
    })
  })

  HTSteps.forEach((step, index) => {
    const { config } = step.input ?? {}
    const { output, transient_data } = step.results[0] ?? {}
    const { document } = output ?? {}

    let inProgress = false
    let name = 'Human Translation'
    if (HTSteps.length > 1) name += ` ${index + 1}`

    if (!document) {
      if (!transient_data) return out
      segments = parseTasksIntoSegments(transient_data.task_results)
      inProgress = true
    } else {
      const tasks = step.input?.transient_data?.task_results
      segments = parseSegments(document.target_segments, { ...config, tasks })
    }

    out.push({
      name,
      number_of_segments: segments.length,
      language: getLanguage(document?.target_language ?? config.target_language),
      segments,
      inProgress
    })
  })

  return out
}

export function getFastMTSegments (step, config) {
  const { results, input } = step ?? {}
  const { output } = results?.[0] ?? {}
  const out = []

  if (!output || !input?.config?.mt_configs) return out

  const mtConfig = input.config.mt_configs[input.config.mt_configs.length - 1]

  const name = 'Machine Translation'
  let nuggets = []

  const { translated_data } = output ?? {}

  nuggets = __parseNuggetsForAnnotations(translated_data.nuggets, 'source', config)

  out.push({
    name: 'Source',
    type: 'fastmt',
    number_of_segments: nuggets.length,
    language: getLanguage(mtConfig.source_language),
    segments: nuggets
  })

  nuggets = __parseNuggetsForAnnotations(translated_data.nuggets, 'mt', config)
  out.push({
    name,
    type: 'fastmt',
    number_of_segments: nuggets.length,
    request_engine: mtConfig.model_service.name,
    language: getLanguage(mtConfig.target_language),
    segments: nuggets
  })

  return out
}
