<template>
  <div>{{ label }}</div>
</template>

<script>
export default {
  name: 'AsyncEntityNameCell',
  props: {
    id: {
      type: String,
      required: false
    },
    entityKey: {
      type: String,
      default: 'name'
    },
    fetch: {
      type: Function,
      required: true
    },
    emptyText: {
      type: String,
      default: '---'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      entity: null,
      internalLoading: false
    }
  },
  computed: {
    isLoading () {
      return this.loading || this.internalLoading
    },
    label () {
      if (this.isLoading) {
        return '...'
      }

      return this.entity?.[this.entityKey] ?? this.emptyText
    }
  },
  watch: {
    id: {
      async handler (id) {
        this.internalLoading = true
        this.entity = await this.fetch(id)
        this.internalLoading = false
      },
      immediate: true
    }
  }
}
</script>
