<template>
  <div>
    <a
      v-if="isSegmentATM(segment)"
      :class="generateTMClass(segment)"
      :href="generateTMServerLink(segment)"
      rel="noopener noreferrer"
      target="_blank"
      :title="generateTMTitle(segment, 'mt')"
      v-html="segment.parsedText"
    />

    <div v-else class="segment_isText" v-html="segment.parsedText" />

    <div v-if="step.request_engine" class="segment_mtFooter">
      <span v-if="segment.qe_score >= 0" class="qeScore">
        QE: {{ parseQEScore(segment.qe_score) }}
      </span>

      <StatusChip
        v-if="isFallbackEngine(segment)"
        :status="`Engine: ${segment.mt_engine}`"
        :status-config="fallbackEngineClass"
        tooltipMessage="Fallback engine"
      />
    </div>
  </div>
</template>

<script>
import { generateTitleToTm, getMatchType } from '@/utils/generalUtils'
import StatusChip from '@/components/StatusChip'

export default {
  name: 'FastMTSegment',
  components: {
    StatusChip
  },
  props: {
    segment: {
      type: Object,
      required: true
    },
    step: {
      type: Object,
      required: true
    }
  },
  computed: {
    fallbackEngineClass () {
      return {
        labelType: 'is-subtle',
        labelStyle: 'is-attention'
      }
    }
  },
  methods: {
    isFallbackEngine (segment) {
      const whitelist = ['tm', 'untranslatable', this.step.request_engine]
      return !whitelist.includes(segment.mt_engine)
    },
    isSegmentATM (segment) {
      return segment.tm_entry_id?.length && segment.tm_translation_id?.length
    },
    generateTMClass (segment) {
      // checks for tm_match_type, blocking_reason inside segment
      const matchType = getMatchType(segment)
      let out = 'nugget_isTm'

      if (matchType) {
        out += ` ${matchType}`
      }

      if (segment.is_blocked || segment.tm_is_blocked_for_editors) {
        out += ' blocked'
      }

      return out
    },
    generateTMServerLink (segment) {
      return `${process.env.VUE_APP_TM_SERVER_BASE_URL}/entry/${segment.tm_entry_id}/`
    },
    parseQEScore (qeScore) {
      return (Math.round(qeScore * 100) / 100).toFixed(2)
    },
    generateTMTitle (segment, segmentType) {
      return generateTitleToTm(segment, segmentType)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.segment_isText {
  ::v-deep span {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  ::v-deep .markup_tag {
    margin: 0 1px;
    background-color: #f2ebf5;
    border: 1px solid #a36fbb;
    color: $un-n900 !important;
    font-weight: 600 !important;
    border-radius: 6px;
    padding: 2px 8px;

    &.self_closing {
      box-shadow: inset 0 -2px 0 0 #a36fbb;
    }

    &.outstanding {
      display: inline-block;
      vertical-align: middle;
      background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.4238 12.8329C13.4238 13.159 13.1596 13.4233 12.8338 13.4233H10.3377C10.1317 12.506 9.73782 11.6472 9.1869 10.8987C9.92868 10.6238 10.7145 10.4834 11.5073 10.4851H11.5081C12.1576 10.4847 12.8032 10.5809 13.4238 10.7701V12.8329ZM13.4238 10.1695V3.16704C13.4238 2.84098 13.1596 2.57666 12.8338 2.57666H3.17502C2.8492 2.57666 2.58507 2.84098 2.58507 3.16704V8.22099C2.92196 8.17368 3.26192 8.15027 3.60241 8.15101C5.20124 8.11569 6.76073 8.64955 8.003 9.65753C8.29316 9.89297 8.56161 10.1507 8.80644 10.4275C9.66491 10.0829 10.5821 9.90648 11.5082 9.90844C12.1562 9.90811 12.8006 9.9961 13.4238 10.1695ZM10.1088 13.9999C10.1047 14 10.1006 14 10.0965 13.9999H3.17502C2.53096 13.9999 2.00884 13.4774 2.00884 12.8329V8.63305C2.00746 8.62759 2.00624 8.62207 2.00517 8.61648C1.99699 8.5736 1.99875 8.53113 2.00884 8.49144V3.16704C2.00884 2.5225 2.53096 2 3.17502 2H12.8338C13.4779 2 14 2.5225 14 3.16704V12.8329C14 13.4774 13.4779 13.9999 12.8338 13.9999H10.1088ZM3.17502 13.4233H9.74571C9.4242 12.1206 8.68652 10.9545 7.64009 10.1054C6.50299 9.18281 5.07532 8.69448 3.61179 8.7276L3.60451 8.72767C3.26312 8.72676 2.9223 8.75223 2.58507 8.8038V12.8329C2.58507 13.159 2.8492 13.4233 3.17502 13.4233ZM8.7228 6.02287C8.7228 5.33283 9.28177 4.77344 9.9713 4.77344C10.6608 4.77344 11.2198 5.33283 11.2198 6.02287C11.2198 6.7129 10.6608 7.27229 9.9713 7.27229C9.28177 7.27229 8.7228 6.7129 8.7228 6.02287ZM9.9713 4.19679C8.96353 4.19679 8.14656 5.01435 8.14656 6.02287C8.14656 7.03138 8.96353 7.84895 9.9713 7.84895C10.9791 7.84895 11.796 7.03138 11.796 6.02287C11.796 5.01435 10.9791 4.19679 9.9713 4.19679Z" fill="black"/></svg>');
      background-position: center;
      background-repeat: no-repeat;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
}

.segment_mtFooter {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-top: 0.7rem;
  gap: 0.5rem;

  .qeScore {
    font-family: $secondary-font;
    font-size: 10px;
    letter-spacing: 1.5px;
    line-height: 15px;
    color: $un-n500;
  }
}
</style>
