<template>
  <div class="v-Translations">
    <TranslationFilters class="mb-2" />
    <TranslationsTable
      :class="{ disabled: !!selectedTranslation }"
      :data="translations"
    />
    <TranslationSidePanel v-if="selectedTranslation" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TranslationFilters from '../components/TranslationFilters.vue'
import TranslationsTable from '../components/TranslationsTable.vue'
import TranslationSidePanel from '../components/TranslationSidePanel.vue'

export default {
  name: 'Translations',
  components: { TranslationsTable, TranslationSidePanel, TranslationFilters },
  mounted () {
    this.$store.commit('watchtower/resetState')
  },
  computed: {
    ...mapState('watchtower', ['translations', 'selectedTranslation'])
  },
  watch: {
    $route: {
      handler: function () {
        const params = this.$route.params
        this.$store.commit('watchtower/setStateValue', {
          field: 'selectedTranslation',
          value: params.id ?? null
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-Translations {
  .disabled {
    pointer-events: none;
    overflow: hidden;
    position: static;
  }
}
</style>
