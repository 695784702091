<template>
  <div>
    <TranslationDetailsHeader :translation="translation" />

    <TranslationFlowDetails class="mb-2" :translation="translation" />

    <TranslationFlowMessageDetails class="mb-2" :translation="translation" />

    <TranslationFlowText class="mb-2" :translation="translation" />

    <TranslationMTDetails
      v-for="(config, index) in configs"
      :key="index"
      :config="config.mt"
      :qeConfig="config.qe"
      class="mb-2"
    />
  </div>
</template>

<script>
import TranslationDetailsHeader from './TranslationDetailsHeader'
import TranslationFlowDetails from './TranslationFlowDetails'
import TranslationFlowMessageDetails from './TranslationFlowMessageDetails'
import TranslationFlowText from './TranslationFlowText'
import TranslationMTDetails from './TranslationMTDetails'

export default {
  name: 'TranslationDetails',
  components: {
    TranslationDetailsHeader,
    TranslationFlowDetails,
    TranslationFlowMessageDetails,
    TranslationFlowText,
    TranslationMTDetails
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  computed: {
    configs () {
      const steps = []

      this.translation.stepExecution.forEach(step => {
        const { config } = step.input ?? {}
        const { output } = step.results?.find(result => result?.output) ?? {}
        let qe, mt

        if (!config) return

        if (config.config_type === 'machine_translation') {
          mt = {
            ...step.input.config,
            job_engine: output?.translated_data?.job_engine,
            job_version: output?.translated_data?.job_engine_model_version
          }
          qe = config.qe_config
          steps.push({ mt, qe })
        }

        if (config.config_type === 'fastmt' && config.mt_configs) {
          if (config.qe_config) {
            qe = {
              threshold: config.qe_config.qe_score_threshold,
              score: output?.quality_score
            }
          }

          config.mt_configs.forEach(mtConfig => {
            mt = {
              ...mtConfig,
              config_type: 'fastmt',
              job_engine: output?.translated_data?.job_engine,
              job_version: output?.translated_data?.job_engine_model_version
            }

            steps.push({ mt, qe })
          })
        }
      })

      return steps
    }
  }
}
</script>
