<template>
  <div id="app">
    <Header
      :user="user"
      :isShortcutsModalVisible="isShortcutsModalVisible"
      @toggleShortcutsModal="shortcutsModalVisibility"></Header>
    <Modal
      title="Keyboard Shortcuts"
      :widthPx="355"
      :is-visible="isShortcutsModalVisible"
      :disableBodyPadding="true"
      @toggleVisibility="shortcutsModalVisibility(false)">
      <div class="shortcutsModalContent">
        <div>
          <ul>
            <li>
              <span>Open this popup with</span>
              <div>
                <i>⌘</i>
                <i>K</i>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <p>Filters</p>
          <ul>
            <li>
              <span>Select option</span>
              <div>
                <i>Enter</i>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <p>Search view</p>
          <ul>
            <li>
              <span>Open in new tab</span>
              <div>
                <i>⌘</i>
                <span>+ Click</span>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <p>Side panel</p>
          <ul>
            <li>
              <span>Next item in list</span>
              <div>
                <i>→</i>
              </div>
            </li>
            <li>
              <span>Previous item in list</span>
              <div>
                <i>←</i>
              </div>
            </li>
            <li>
              <span>Exit sidepanel</span>
              <div>
                <i>Esc</i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
    <div class="appContainer">
      <Breadcrumbs
        class="appContainer__breadcrumbs"
        :visible="areBreadcrumbsVisible"
        :items="breadcrumbs"
        @click="breadcrumbClick"></Breadcrumbs>
      <router-view/>
    </div>
    <Loader :active="loadingState"></Loader>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GLOBAL_GETTERS } from '@/data/enum'
import { parseBreadcrumbRoutes, handleBreadrumbsClick } from '@/utils/generalUtils'
import Header from '@/components/Header.vue'
import Modal from '@/components/Modal'
import {
  Loader,
  Breadcrumbs
} from '@unbabel/ui'

export default {
  components: {
    Header,
    Breadcrumbs,
    Loader,
    Modal
  },
  data () {
    return {
      isShortcutsModalVisible: false
    }
  },
  computed: {
    ...mapGetters({
      loadingState: GLOBAL_GETTERS.LOADING,
      messageDetails: GLOBAL_GETTERS.MESSAGE_DETAILS,
      user: GLOBAL_GETTERS.USER
    }),
    breadcrumbs () {
      return parseBreadcrumbRoutes(this.$route, this.messageDetails)
    },
    areBreadcrumbsVisible () {
      return !['Search', 'Changelog', 'Flexible'].includes(this.$route.name)
    }
  },
  methods: {
    breadcrumbClick (item) {
      handleBreadrumbsClick(this.$router, this.$route, item)
    },
    shortcutsModalVisibility (val) {
      this.isShortcutsModalVisible = val
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/fonts.scss";
@import "@/scss/variables.scss";
@import "@/scss/overrides.scss";

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $un-n50;
}

#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: calc(#{$header-height} + calc(#{$base-padding} * 2.5))  calc(#{$base-padding} * 3.5) 0 calc(#{$base-padding} * 3.5);
  height: 100%;
}

.shortcutsModalContent {
  > div {
    border-bottom: 1px solid $un-n200;
    padding: calc(#{$base-padding} * 1.5);

    &:last-child {
      border-bottom: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: $base-padding 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  p {
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $un-n900;
  }

  span {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $un-n900;
  }

  i {
    padding: calc(#{$base-padding} / 2) $base-padding;
    background-color: #AEB9CB;
    box-shadow: 1px 2px 0px #707A89;
    border-radius: 8px;
    font-family: $primary-font;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $un-n900;
    margin-right: calc(#{$base-margin} / 2);

    &:last-child {
      margin-right: 0;
    }
  }
}

.appContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  padding-bottom: calc(#{$base-padding} * 3.5);

  &__breadcrumbs {
    margin-top: -(calc(#{$base-margin} / 2)) !important;
    padding-bottom: calc(#{$base-padding} * 2.5) !important;
  }
}
</style>
