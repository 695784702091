<template>
  <tr class="c-LoadingRow">
    <td><span /></td>
    <td>
      <span />
      <span />
    </td>
    <td>
      <span />
      <span />
    </td>
    <td><span /></td>
    <td><span /></td>
    <td><span /></td>
    <td><span /></td>
    <td>
      <span />
      <span />
      <span />
    </td>
    <td>
      <span />
      <span />
      <span />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TranslationsLoadingRow'
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-LoadingRow {
  td {
    height: 96px;
  }

  td span {
    height: 16px;
    background: #e9ecf2;
    border-radius: 61px;
    display: block;
    width: 100%;
  }

  td span:first-child {
    margin-bottom: calc(#{$base-margin} / 2);
  }

  td:nth-child(1) span {
    max-width: 30px;
  }

  td:nth-child(2) span:first-child {
    max-width: 61px;
  }

  td:nth-child(2) span:last-child {
    max-width: 124px;
  }

  td:nth-child(3) span:first-child {
    max-width: 61px;
  }

  td:nth-child(3) span:last-child {
    max-width: 157px;
  }

  td:nth-child(4) span {
    max-width: 171px;
    height: 41px;
  }

  td:nth-child(5) span:first-child {
    max-width: 166px;
  }

  td:nth-child(6) span:first-child {
    max-width: 102px;
  }

  td:nth-child(7) span:first-child {
    max-width: 65px;
  }

  td:nth-child(8) span,
  td:nth-child(9) span {
    max-width: 379px;

    &:nth-child(2) {
      margin-bottom: calc(#{$base-margin} / 2);
    }
  }
}
</style>
