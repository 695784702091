<template>
  <Card class="c-TranslationFlowMessageDetails" title="Pipeline Group">
    <div
      slot="header-actions"
      class="c-TranslationFlowMessageDetails__cardActions"
    >
      <a
        v-if="customer"
        :href="redirect('translation_profile')"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          label="Portal"
          button-type="is-outlined"
          icon="external-hyperlink"
        />
      </a>
    </div>

    <div slot="body" class="c-TranslationFlowMessageDetails__cardBody">
      <CardBodyLayout>
        <tr colspan="6">
          <td>
            <p>Client</p>
            <p>
              <AsyncEntityNameCell
                :id="customerID"
                :fetch="(id) => getCustomer({ id })"
                entityKey="friendly_name"
              />
            </p>
          </td>
          <td>
            <p>Pipeline Group</p>
            <p>{{ pipelineGroupName }}</p>
          </td>
          <td>
            <p>Customer ID</p>
            <div
              class="c-TranslationFlowMessageDetails__customerID"
              @click="copyText(customerID, 'Customer')"
            >
              <p>{{ customerID }}</p>
              <i />
            </div>
          </td>
          <td>
            <p>Register</p>
            <p>{{ register }}</p>
          </td>
          <td v-if="glossary">
            <p>Glossary</p>
            <div>
              <a
                :href="redirect('glossary')"
                target="_blank"
                rel="noopener noreferrer"
                class="glossary-btn"
              >
                <Icon icon="external-hyperlink" />
                Open glossary
              </a>
            </div>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { mapActions } from 'vuex'
import { Card, Button, Icon } from '@unbabel/ui'
import {
  generateCPLinks,
  copyToClipboard,
  showNotification,
  getTagValue
} from '@/utils/generalUtils'
import CardBodyLayout from '@/components/CardBodyLayout'
import AsyncEntityNameCell from '../AsyncEntityNameCell'

export default {
  name: 'TranslationFlowMessageDetails',
  components: {
    Card,
    Button,
    Icon,
    AsyncEntityNameCell,
    CardBodyLayout
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return { customer: null }
  },
  computed: {
    customerID () {
      return this.translation.request.customer_id || 'Unkwown'
    },
    register () {
      return this.translation.request.tone
    },
    pipelineGroupName () {
      return (
        getTagValue(this.translation.workflow, 'translation_profile_name') ??
        this.translation.workflow.brand_id ??
        'Unkwown'
      )
    },
    glossary () {
      return (
        this.translation.workflow?.steps?.[0]?.config?.build_config
          ?.glossary_id
      )
    }
  },
  methods: {
    ...mapActions({
      getCustomer: 'customers/getCustomer'
    }),
    copyText (id, entity) {
      copyToClipboard(id)
      showNotification(this, 'is-added', `Successfully copied the ${entity} ID`)
    },
    redirect (type) {
      return generateCPLinks(
        {
          customer: this.customer,
          translation_profile: {
            id: this.translation.workflow?.brand_id,
            glossary_id: this.glossary
          }
        },
        type
      )
    }
  },
  created () {
    this.getCustomer({ id: this.translation.request.customer_id }).then(customer => {
      this.customer = customer
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationFlowMessageDetails {
  &__customerID {
    display: inline-flex;
    cursor: pointer;

    i {
      opacity: 0;
      width: 24px;
      height: 24px;
      margin-left: 4px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    }

    &:hover {
      text-decoration: underline;
      i {
        opacity: 1;
      }
    }
  }

  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  td {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0 !important;
    }
  }

  td {
    vertical-align: middle;

    .glossary-btn {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-left: auto;
      margin-bottom: 0.5em;

      .c-Icon {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px;
      }
    }
  }
}
</style>
