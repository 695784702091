import Translations from '../watchtower/views/Translations.vue'
import Home from '../watchtower/views/Home.vue'

export default [
  {
    path: '/flexible',
    component: Home,
    children: [
      {
        path: ':id?',
        name: 'Flexible',
        component: Translations
      }
    ]
  }
]
