<template>
  <Card class="c-TranslationMTDetails" :title="title">
    <div slot="body" class="c-TranslationMTDetails__cardBody">
      <CardBodyLayout>
        <tr colspan="4">
          <td>
            <p>Languages</p>
            <p>{{ languages }}</p>
          </td>
          <td>
            <p>Job engine</p>
            <p>{{ config.job_engine || '-' }}</p>
          </td>
          <td>
            <p>Job engine version</p>
            <p>{{ config.job_version || '-' }}</p>
          </td>
          <td>
            <p>Model name</p>
            <p>{{ modelName }}</p>
          </td>
          <td v-if="qeConfig" class="c-TranslationMTDetails__qe">
            <p>
              <span>QE score</span>
              <Tooltip class="tooltip" message="Score / Threshold" />
            </p>
            <p>{{ score }} / {{ qeConfig.threshold }}</p>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { languageOptions } from '@/data/languages'
import { Card, Tooltip } from '@unbabel/ui'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'TranslationMTDetails',
  components: {
    Card,
    Tooltip,
    CardBodyLayout
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    qeConfig: {
      type: Object,
      required: false
    }
  },
  computed: {
    score () {
      return this.qeConfig?.score?.toFixed(2) ?? '-'
    },
    title () {
      if (this.config.config_type === 'fastmt') {
        return 'FastMT'
      }
      return 'Machine Translation'
    },
    languages () {
      const source =
        languageOptions[this.config.source_language]?.label ??
        this.config.source_language

      const target =
        languageOptions[this.config.target_language]?.label ??
        this.config.target_language

      return `${source} > ${target}`
    },
    modelName () {
      return this.config.model_service.name
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationMTDetails {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__qe {
    p:first-child {
      display: flex;
      margin-bottom: -1px !important;

      .tooltip {
        margin: -2px 0 0 calc(#{$base-margin} / 2);
      }
    }
  }
}
</style>
