<template>
  <div class="c-TranslationDetailsHeader">
    <div class="c-TranslationDetailsHeader__leftBlock">
      <div class="c-TranslationDetailsHeader__title">
        <div>
          <h5>
            {{ title }}
            <Popover
              v-if="popoverData.title.length"
              :data="popoverData.title"
              h-position="left"
            />
            <StatusChip class="ml-2" v-bind="statusChip" />
          </h5>

          <ul>
            <li v-if="author" class="d-flex text--ellipse">by {{ author }}</li>
            <li v-if="origin" class="d-flex text--ellipse">
              from {{ origin }}
            </li>
            <li v-if="usecaseLabel" class="d-flex text--ellipse">
              {{ usecaseLabel }}
            </li>
            <li v-if="projectData" class="d-flex text--ellipse">
              Project:
              <a
                :href="projectLink"
                target="_blank"
                rel="noopener noreferrer"
                class="ml-2"
              >
                <AsyncEntityNameCell
                  :id="projectData.id"
                  :fetch="id => getProject({ id, customerId })"
                  :loading="gettingCustomer"
                  emptyText="unknown"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="c-TranslationDetailsHeader__rightBlock">
      <template v-if="projectData">
        <IdCopyBlock
          :id="projectData.id"
          icon="projectId"
          label="Project ID"
          :is-new-version="true"
        />
        <IdCopyBlock
          :id="projectData.orderId"
          icon="projectId"
          label="Order ID"
          :is-new-version="true"
        />
        <IdCopyBlock
          :id="projectData.jobId"
          icon="projectId"
          label="Job ID"
          :is-new-version="true"
        />
      </template>

      <template>
        <IdCopyBlock
          :id="translationIDs.messageId"
          icon="uid"
          label="Message UID"
          :is-new-version="true"
        />
        <IdCopyBlock
          :id="translationIDs.threadId"
          icon="chatId"
          label="Thread ID"
          :is-new-version="true"
        />
      </template>
      <Popover v-if="popoverData.ids.length" :data="popoverData.ids" />
    </div>
  </div>
</template>

<script>
import Popover from '@/components/Popover.vue'
import StatusChip from '@/components/StatusChip'
import IdCopyBlock from '@/components/IdCopyBlock'
import AsyncEntityNameCell from '../AsyncEntityNameCell.vue'

import { USE_CASE_LABEL } from '../../constants/translations'
import { parseTranslationStatusToStyle } from '../../services/parsers'
import { CONTENT_TYPE, FILTERS_DICT } from '@/data/enum'
import { capitalize, getTagValue, generateCPLinks } from '@/utils/generalUtils'
import { mapActions } from 'vuex'

export default {
  name: 'TranslationDetailsHeader',
  components: {
    IdCopyBlock,
    StatusChip,
    Popover,
    AsyncEntityNameCell
  },
  props: {
    translation: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      projectLink: '',
      gettingCustomer: false
    }
  },
  computed: {
    statusChip () {
      return {
        status: this.translation.status,
        statusConfig: parseTranslationStatusToStyle(this.translation.status)
      }
    },
    requestData () {
      return this.translation.request
    },
    projectData () {
      const projectId = getTagValue(this.requestData, 'x-unbabel-project-id')

      if (!projectId) return null

      return {
        id: projectId,
        orderId: getTagValue(this.requestData, 'x-unbabel-order-id'),
        jobId: getTagValue(this.requestData, 'x-unbabel-job-id')
      }
    },
    customerId () {
      return this.requestData.customer_id
    },
    contentType () {
      return getTagValue(this.requestData, 'content_type')
    },
    direction () {
      return getTagValue(this.requestData, 'direction')
    },
    author () {
      return getTagValue(this.requestData, 'author_id')
    },
    origin () {
      return getTagValue(this.requestData, 'origin')
    },
    usecaseLabel () {
      return USE_CASE_LABEL[getTagValue(this.requestData, 'use_case')]
    },
    title () {
      const isFaq = this.contentType === CONTENT_TYPE.FAQ
      let msg = isFaq ? 'Translation' : 'Message'

      if (this.contentType) {
        msg = `${capitalize(this.contentType)} ${msg}`
      }

      if (!isFaq && this.direction && this.direction !== 'unknown') {
        msg = `${capitalize(this.direction)} ${msg}`
      }

      return msg
    },
    popoverData () {
      let out = {
        title: [
          {
            label: 'Content-type',
            value: this.contentType
          },
          {
            label: 'Direction',
            value: this.direction
          },
          {
            label: 'Author ID',
            value: this.author,
            type: 'author_id',
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Agent ID',
            value: getTagValue(this.requestData, 'agent_id'),
            type: 'agent_id',
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Origin',
            value: this.origin
          },
          {
            label: 'Use case',
            value: this.usecaseLabel
          },
          {
            label: 'Document type',
            value: getTagValue(this.requestData, 'text_format')
          }
        ],
        ids: [
          {
            label: 'Message UID',
            value: getTagValue(this.requestData, 'message_id'),
            type: FILTERS_DICT.UID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Thread ID',
            value: getTagValue(this.requestData, 'thread_id'),
            type: FILTERS_DICT.THREAD_ID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Ext. Message ID',
            value: getTagValue(this.requestData, 'external_message_id'),
            type: FILTERS_DICT.EXTERNAL_ID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Ext. Thread ID',
            value: getTagValue(this.requestData, 'external_thread_id'),
            type: FILTERS_DICT.THREAD_EXTERNAL_ID,
            canCopy: true,
            isIdCopied: false
          }
        ]
      }

      if (this.projectData) {
        out.title.push({
          label: 'Project',
          value: this.projectData.id
        })

        out.ids.unshift(
          ...[
            {
              label: 'Project ID',
              value: this.projectData.id,
              type: FILTERS_DICT.THREAD_EXTERNAL_ID,
              canCopy: true,
              isIdCopied: false
            },
            {
              label: 'Order ID',
              value: this.projectData.orderId ?? 'Unknown',
              type: FILTERS_DICT.THREAD_EXTERNAL_ID,
              canCopy: true,
              isIdCopied: false
            },
            {
              label: 'Job ID',
              value: this.projectData.jobId ?? 'Unknown',
              type: FILTERS_DICT.THREAD_EXTERNAL_ID,
              canCopy: true,
              isIdCopied: false
            }
          ]
        )
      }

      out = {
        ids: out.ids.filter(item => item.value),
        title: out.title.filter(item => item.value)
      }

      return out
    },
    translationIDs () {
      return {
        messageId: getTagValue(this.requestData, 'external_message_id'),
        threadId: getTagValue(this.requestData, 'external_thread_id')
      }
    }
  },
  methods: {
    ...mapActions({
      getProject: 'projects/getProject',
      getCustomer: 'customers/getCustomer'
    })
  },
  created () {
    this.gettingCustomer = true
    const projectId = getTagValue(this.requestData, 'x-unbabel-project-id')

    this.getCustomer({ id: this.requestData.customer_id }).then(customer => {
      this.projectLink = generateCPLinks(
        { project_id: projectId, customer },
        'project'
      )
    }).finally(() => {
      this.gettingCustomer = false
    })
  },
  getProjectLink () {
    const projectId = getTagValue(this.requestData, 'x-unbabel-project-id')

    return generateCPLinks(
      {
        project_id: projectId,
        canonicalName: this.customer?.canonical_name
      },
      'project'
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
$list-style-padding: 18px;

.c-TranslationDetailsHeader {
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  position: relative;

  &__title {
    // this is to align the information underneath the title with the center alignment for the ID's on the right
    margin-bottom: calc(#{$base-margin} / 2);

    h5 {
      display: flex;
      align-items: center;
    }

    ul {
      display: flex;
      margin: 4px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        max-width: 12rem;
        color: $un-n700;

        &:not(:first-child)::before {
          content: "\2022";
          font-size: 24px;
          vertical-align: top;
          margin: 0 calc(#{$base-margin} / 2);
        }

        &:first-child {
          list-style: none;
        }
      }
    }
  }

  &__rightBlock {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 4px;

    & > div:nth-child(1) {
      display: flex;
    }
  }
}
</style>
