export const TranslationState = {
  PENDING: 'Pending',
  IN_PROGRESS: 'Running',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
  TERMINATED: 'Terminated',
  TIMED_OUT: 'Timed Out',
  CONTINUE_AS_NEW: 'Continue As New'
}

export const ModuleState = {
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
  TIMED_OUT: 'timed_out',
  CANCEL_REQUESTED: 'cancel_requested',
  CANCELED: 'canceled'
}

// Temporal event types
export const WorkflowStatus = {
  WORKFLOW_EXECUTION_STATUS_UNSPECIFIED: 0,
  WORKFLOW_EXECUTION_STATUS_RUNNING: 1,
  WORKFLOW_EXECUTION_STATUS_COMPLETED: 2,
  WORKFLOW_EXECUTION_STATUS_FAILED: 3,
  WORKFLOW_EXECUTION_STATUS_CANCELED: 4,
  WORKFLOW_EXECUTION_STATUS_TERMINATED: 5,
  WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW: 6,
  WORKFLOW_EXECUTION_STATUS_TIMED_OUT: 7
}

export const EventType = {
  EVENT_TYPE_UNSPECIFIED: 0,
  EVENT_TYPE_WORKFLOW_EXECUTION_STARTED: 1,
  EVENT_TYPE_WORKFLOW_EXECUTION_COMPLETED: 2,
  EVENT_TYPE_WORKFLOW_EXECUTION_FAILED: 3,
  EVENT_TYPE_WORKFLOW_EXECUTION_TIMED_OUT: 4,
  EVENT_TYPE_WORKFLOW_TASK_SCHEDULED: 5,
  EVENT_TYPE_WORKFLOW_TASK_STARTED: 6,
  EVENT_TYPE_WORKFLOW_TASK_COMPLETED: 7,
  EVENT_TYPE_WORKFLOW_TASK_TIMED_OUT: 8,
  EVENT_TYPE_WORKFLOW_TASK_FAILED: 9,
  EVENT_TYPE_ACTIVITY_TASK_SCHEDULED: 10,
  EVENT_TYPE_ACTIVITY_TASK_STARTED: 11,
  EVENT_TYPE_ACTIVITY_TASK_COMPLETED: 12,
  EVENT_TYPE_ACTIVITY_TASK_FAILED: 13,
  EVENT_TYPE_ACTIVITY_TASK_TIMED_OUT: 14,
  EVENT_TYPE_ACTIVITY_TASK_CANCEL_REQUESTED: 15,
  EVENT_TYPE_ACTIVITY_TASK_CANCELED: 16,
  EVENT_TYPE_TIMER_STARTED: 17,
  EVENT_TYPE_TIMER_FIRED: 18,
  EVENT_TYPE_TIMER_CANCELED: 19,
  EVENT_TYPE_WORKFLOW_EXECUTION_CANCEL_REQUESTED: 20,
  EVENT_TYPE_WORKFLOW_EXECUTION_CANCELED: 21,
  EVENT_TYPE_REQUEST_CANCEL_EXTERNAL_WORKFLOW_EXECUTION_INITIATED: 22,
  EVENT_TYPE_REQUEST_CANCEL_EXTERNAL_WORKFLOW_EXECUTION_FAILED: 23,
  EVENT_TYPE_EXTERNAL_WORKFLOW_EXECUTION_CANCEL_REQUESTED: 24,
  EVENT_TYPE_MARKER_RECORDED: 25,
  EVENT_TYPE_WORKFLOW_EXECUTION_SIGNALED: 26,
  EVENT_TYPE_WORKFLOW_EXECUTION_TERMINATED: 27,
  EVENT_TYPE_WORKFLOW_EXECUTION_CONTINUED_AS_NEW: 28,
  EVENT_TYPE_START_CHILD_WORKFLOW_EXECUTION_INITIATED: 29,
  EVENT_TYPE_START_CHILD_WORKFLOW_EXECUTION_FAILED: 30,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_STARTED: 31,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_COMPLETED: 32,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_FAILED: 33,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_CANCELED: 34,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_TIMED_OUT: 35,
  EVENT_TYPE_CHILD_WORKFLOW_EXECUTION_TERMINATED: 36,
  EVENT_TYPE_SIGNAL_EXTERNAL_WORKFLOW_EXECUTION_INITIATED: 37,
  EVENT_TYPE_SIGNAL_EXTERNAL_WORKFLOW_EXECUTION_FAILED: 38,
  EVENT_TYPE_EXTERNAL_WORKFLOW_EXECUTION_SIGNALED: 39,
  EVENT_TYPE_UPSERT_WORKFLOW_SEARCH_ATTRIBUTES: 40,
  EVENT_TYPE_WORKFLOW_EXECUTION_UPDATE_ADMITTED: 47,
  EVENT_TYPE_WORKFLOW_EXECUTION_UPDATE_ACCEPTED: 41,
  EVENT_TYPE_WORKFLOW_EXECUTION_UPDATE_REJECTED: 42,
  EVENT_TYPE_WORKFLOW_EXECUTION_UPDATE_COMPLETED: 43,
  EVENT_TYPE_WORKFLOW_PROPERTIES_MODIFIED_EXTERNALLY: 44,
  EVENT_TYPE_ACTIVITY_PROPERTIES_MODIFIED_EXTERNALLY: 45,
  EVENT_TYPE_WORKFLOW_PROPERTIES_MODIFIED: 46,
  EVENT_TYPE_NEXUS_OPERATION_SCHEDULED: 48,
  EVENT_TYPE_NEXUS_OPERATION_STARTED: 49,
  EVENT_TYPE_NEXUS_OPERATION_COMPLETED: 50,
  EVENT_TYPE_NEXUS_OPERATION_FAILED: 51,
  EVENT_TYPE_NEXUS_OPERATION_CANCELED: 52,
  EVENT_TYPE_NEXUS_OPERATION_TIMED_OUT: 53,
  EVENT_TYPE_NEXUS_OPERATION_CANCEL_REQUESTED: 54
}

export const StatusToTranslationState = {
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_UNSPECIFIED]: TranslationState.IN_PROGRESS,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_RUNNING]: TranslationState.IN_PROGRESS,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW]: TranslationState.CONTINUE_AS_NEW,

  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_COMPLETED]: TranslationState.COMPLETED,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_FAILED]: TranslationState.FAILED,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_CANCELED]: TranslationState.CANCELED,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_TERMINATED]: TranslationState.TERMINATED,
  [WorkflowStatus.WORKFLOW_EXECUTION_STATUS_TIMED_OUT]: TranslationState.TIMED_OUT
}

export const EventToModuleState = {
  [EventType.EVENT_TYPE_ACTIVITY_TASK_SCHEDULED]: ModuleState.SCHEDULED,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_STARTED]: ModuleState.IN_PROGRESS,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_COMPLETED]: ModuleState.COMPLETED,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_FAILED]: ModuleState.FAILED,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_TIMED_OUT]: ModuleState.TIMED_OUT,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_CANCEL_REQUESTED]: ModuleState.CANCEL_REQUESTED,
  [EventType.EVENT_TYPE_ACTIVITY_TASK_CANCELED]: ModuleState.CANCELED
}
