import axios from 'axios'

import { showNotification } from '@/utils/generalUtils'

const BASE_URL = `${process.env.VUE_APP_CUSTOMER_API_URL}/latest/v4/customers`

export async function getCustomer (id) {
  try {
    const { data } = await axios.get(`${BASE_URL}/${id}`)
    return data
  } catch (e) {
    console.error(e)
  }
}

export async function searchCustomers (query) {
  const requestUrl = `${BASE_URL}?limit=20&name=${encodeURIComponent(query)}`

  try {
    const { data } = await axios.get(requestUrl)
    return data.results
  } catch (e) {
    console.error(e)
    showNotification(this, 'is-alert', `Error searching for customers :: ${e}`)
  }
}
