/* eslint-disable camelcase */
import { SEARCH_FILTERS_CONFIG } from '@/data/filters'
import {
  ARCH_STATUS,
  CONTENT_TYPE,
  TASK_TYPE,
  BLOCKING_BY,
  BLOCKING_REASON,
  MODULE
} from '@/data/enum'
import mixpanel from 'mixpanel-browser'
import routes from '@/router/routes'

export function showNotification (ctx, type, msg) {
  ctx.$toasted.show(msg, {
    className: `toast-notifications ${type}`,
    duration: 5000,
    action: {
      text: 'dismiss',
      class: 'dismiss',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  })
}

export function debounce (callback, time) {
  let interval
  return (...args) => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      interval = null
      // eslint-disable-next-line
      callback(...args)
    }, time)
  }
}

export function copyToClipboard (text) {
  var el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  return true
}

export function downloadFile (filename, text) {
  var element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export function truncateString (text, numChars, truncateStart = false) {
  if (text && text.length && text.length > numChars) {
    if (truncateStart) {
      const res = text.slice(-numChars)
      return `…${res}`
    } else {
      const res = text.slice(0, numChars)
      return `${res}…`
    }
  }

  return text
}

export function generateFlowName (msgDetails) {
  const arch = msgDetails?.translation_profile?.architecture || null
  const isPivoted = msgDetails?.translation_profile?.pivoted || null
  const isHt = msgDetails?.phases?.find(item => item.name === MODULE.HT) || null

  if (arch === ARCH_STATUS.CORE) {
    return 'Core MT Flow'
  } else if (!isHt && (arch === ARCH_STATUS.CORE_FALLBACK || (arch === ARCH_STATUS.MAESTRO && !isPivoted))) {
    return 'Maestro MT Flow'
  } else if (isHt && (arch === ARCH_STATUS.CORE_FALLBACK || (arch === ARCH_STATUS.MAESTRO && !isPivoted))) {
    return 'Maestro MT+HT Flow'
  } else if (arch === ARCH_STATUS.MAESTRO && isPivoted) {
    return 'Maestro Pivoted Flow'
  }

  return '---'
}

export function generateLinkToCore (uid, isHuman) {
  if (isHuman) {
    return `${process.env.VUE_APP_CORE_BASE_URL}/tpadmin/jobs/?filter_query=uid%3A${uid}`
  }

  return `${process.env.VUE_APP_CORE_BASE_URL}/tpadmin/mt-job/${uid}`
}

export function parseRequestError (err) {
  return (err.response && err.response.data && err.response.data.message) ? `SERVER ERROR ::: ${err.response.data.message}`
    : (err.response && err.response.data && err.response.data) ? `SERVER ERROR ::: ${err.response.data}`
      : err.error ? `SERVER ERROR ::: ${err.error}` : err
}

export function generateCPLinks (item, type) {
  const canonicalName = item?.customer?.canonical_name || 'CANONICAL_NAME'
  const tpId = item?.translation_profile?.id || 'TP_ID'
  const pipelineId = item?.pipeline?.id || 'PIPELINE_ID'
  const projectId = item?.project_id || 'PROJECT_ID'
  const glossaryId = item?.translation_profile?.glossary_id || 'GLOSSARY_ID'
  const translationId = item?.translationId || 'GLOSSARY_ID'
  let link

  switch (type) {
    case 'customer':
      link = `https://${canonicalName}.unbabel.com`
      break
    case 'glossary':
      link = `https://${canonicalName}.unbabel.com/linguistic-resources/glossaries/${glossaryId}`
      break
    case 'translation_profile':
      link = `https://${canonicalName}.unbabel.com/pipeline-groups/${tpId}`
      break
    case 'translation_profile_lp':
      link = `https://${canonicalName}.unbabel.com/pipeline-groups/${tpId}/pipeline/${pipelineId}`
      break
    case 'project':
      link = `https://${canonicalName}.unbabel.com/projects/${projectId}/orders`
      break
    case 'temporal':
      link = `${process.env.VUE_APP_TEMPORAL_LINK}/workflows/${translationId}`
      break
  }

  return link
}

export function randomizeId (string = '') {
  return `${string}${Math.floor(Math.random() * 100000000000000000000)}`
}

export function trackEvent (eventName, payload) {
  if (mixpanel && mixpanel.__loaded) {
    return mixpanel.track(eventName, payload)
  }
}

export function prefixObjectKeys (obj, prefix) {
  const newObj = {}

  // keep original filter keys so that we don't mess up mixpanel events
  if (prefix === 'search_filters_') {
    Object.keys(obj).forEach(key => {
      newObj[`${prefix}${SEARCH_FILTERS_CONFIG[`${key}`].mixpanelParam}`] = obj[`${key}`]
    })
  } else {
    Object.keys(obj).forEach(key => {
      newObj[`${prefix}${key}`] = obj[`${key}`]
    })
  }

  return newObj
}

export function parseBreadcrumbRoutes (currRoute, msgDetails) {
  // generate label key according to content-type and if we are on the Task level (check ROUTES file)
  const contentType = msgDetails.translation_profile?.content_type
  let labelKey = contentType
  let taskType

  // change label if senior review in faq's
  if (currRoute.params?.taskId) {
    const findTask = msgDetails.human_translation_details?.tasks?.find(item => item.id === currRoute.params?.taskId)
    findTask ? taskType = findTask.type : taskType = undefined

    if (contentType === CONTENT_TYPE.FAQ && taskType === TASK_TYPE.REVIEW) {
      labelKey = `${contentType}_${taskType}`
    }
  }

  // get all the parsable routes from the ROUTES file
  const parsableRoutes = routes.filter(item => item.meta && item.meta.bc__routeParsing)
  const index = parsableRoutes.findIndex(item => item.name === currRoute.name)

  if (index > -1) {
    // If we are on the Sidepanel, we need to add more items to the Breadcrumbs since the current route is the Search
    const isSidepanelCondition = currRoute.query?.pThrId && currRoute.query?.pMsgId
    const slicedRoutesArray = isSidepanelCondition ? parsableRoutes.slice(0, index + 2) : parsableRoutes.slice(0, index + 1)

    // going through the sliced array to compose the breadcrumb items
    const finalBreadcrumbsArray = slicedRoutesArray.map((item, i) => {
      let id

      if (item.meta.bc__visibleRouteParams &&
      item.meta.bc__visibleRouteParams.length &&
      currRoute.params &&
      currRoute.params[item.meta.bc__visibleRouteParams]) {
        id = truncateString(currRoute.params[item.meta.bc__visibleRouteParams], 6, true)
      }

      let label = item.meta.bc__label[`${labelKey}`] ? item.meta.bc__label[`${labelKey}`] : item.meta.bc__label.default
      let isActive = index === i
      let disabled = item.meta.bc__disabled || false

      // if items belong to the "menu" part of the breadcrumbs, compose the label differently
      if (slicedRoutesArray.length > 3 && i > 0 && i < slicedRoutesArray.length - 2) {
        label = `${label} ${id}`
      }

      // condition to display breadcrumbs on sidepanel. This will be refactored once we lose the BC in the main view
      if (isSidepanelCondition) {
        if (item.name === 'Search') {
          isActive = false
        }

        if (item.name === 'MessageDetails') {
          id = truncateString(currRoute.query.pMsgId, 6, true)
          isActive = true
          disabled = true
        }
      }

      return {
        disabled,
        label,
        name: item.name,
        isActive,
        id
      }
    })

    // exception to add thread ID to Breadcrumbs (only add if we have a thread ID). No concept of thread in faq's
    if (contentType !== CONTENT_TYPE.FAQ && msgDetails.thread_id) {
      finalBreadcrumbsArray.splice(1, 0, {
        disabled: false,
        label: `Thread ${truncateString(msgDetails.thread_id, 6, true)}`,
        name: 'Thread',
        isActive: false,
        threadId: msgDetails.thread_id
      })
    }

    return finalBreadcrumbsArray
  }

  return []
}

export function handleBreadrumbsClick (routerInstance, routeInstance, item) {
  // exception for thread ID
  if (item.name === 'Thread') {
    window.open(`/?thread_id=${item.threadId}`, '_self')
  } else {
    const routeNav = { name: item.name }
    if (item.params) {
      routeNav.params = item.params
    }

    if (routeInstance && routeInstance.query) {
      routeNav.query = routeInstance.query
    }

    if (routeInstance.name !== item.name) {
      routerInstance.push(routeNav)
    }
  }
}

export function checkIfUsedFallbackEngine (nugget, engines) {
  const whitelist = ['tm', 'untranslatable', ...engines]
  return nugget && !whitelist.includes(nugget.mt_engine)
}

export function checkIfNuggetIsTm (i, nuggets) {
  return nuggets && nuggets.length && nuggets[i] && nuggets[i].tm_entry_id && nuggets[i].tm_entry_id.length && nuggets[i].tm_translation_id && nuggets[i].tm_translation_id.length
}

export function generateLinkToTmServer (nugget) {
  return `${process.env.VUE_APP_TM_SERVER_BASE_URL}/entry/${nugget.tm_entry_id}/`
}

export function isNuggetBlocked (nugget) {
  return nugget && (nugget.is_blocked || nugget.tm_is_blocked_for_editors)
}

export function generateTitleToTm (nugget, nuggetType) {
  const isBlocked = isNuggetBlocked(nugget)
  const reason = getMatchType(nugget)
  let title = 'Translation Memory'

  if (reason) {
    if (isBlocked) {
      title += `\nBlocking reason: ${reason}`
    } else {
      title += `\nMatch type: ${reason}`
    }
  }

  title += `\r\n\r\ntm_entry_id=${nugget.tm_entry_id}\r\ntm_translation_id=${nugget.tm_translation_id}\r\n`

  if (nuggetType === 'ht') {
    title += `tm_confidence_score=${nugget.tm_confidence_score}\r\ntm_curated=${nugget.tm_curated}\r\n`
    title += `tm_is_blocked_for_editors=${isBlocked}\r\ntm_is_visible_for_editors=${nugget.tm_is_visible_for_editors}\r\n`
    title += `tm_match_by_brand=${nugget.tm_match_by_brand}\r\ntm_match_by_client=${nugget.tm_match_by_client}\r\n`
    title += `tm_match_by_content_type=${nugget.tm_match_by_content_type}\r\ntm_match_by_origin=${nugget.tm_match_by_origin}\r\n`
  }

  return title
}

export function getMatchType (nugget) {
  const { tm_match_type, blocking_reason } = nugget

  if (blocking_reason) {
    if (blocking_reason === BLOCKING_REASON.ICE || blocking_reason === 'ICE') {
      return BLOCKING_BY.ICE
    } else if (blocking_reason === BLOCKING_REASON.QE || blocking_reason === 'QE') {
      return BLOCKING_BY.QE
    }
    return BLOCKING_BY.TM
  }

  if (tm_match_type) {
    if (tm_match_type === 'ICE') {
      return BLOCKING_BY.ICE
    }

    return BLOCKING_BY.TM
  }
}

export function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getTagValue (payload = {}, tag, defaultValue) {
  return payload.tags?.[tag]?.[0] ?? defaultValue
}
